






























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { Seminar, SeminarElement } from "@/core/models";
import { typeName } from "@/core/utils/seminars";
import ElementDialog from "./ElementDialog.vue";

@Component({ components: { ElementDialog } })
export default class AdminElement extends Vue {
  @Prop({ default: () => 0 }) blockId!: number;
  @Prop({ default: () => undefined }) seminar?: Seminar;
  @Prop({ default: () => undefined }) element?: SeminarElement;

  dialog = false;
  tempElement: SeminarElement | null = null;
  addLoading = false;
  editLoading = false;
  deleteLoading = false;
  get loading() {
    return this.editLoading || this.deleteLoading;
  }
  get typeName() {
    if (!this.element) return "";
    return typeName(this.element.type);
  }
  openEditElement() {
    if (!this.element) return;
    this.dialog = true;
    this.tempElement = Object.assign({}, this.element);
  }
}

























































































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

// todo newElement maxreq stuff
import { EditSeminarRequest, Seminar, SeminarBlock } from "@/core/models";

import Sidebar from "../components/Sidebar.vue";
import Participants from "../components/Participants.vue";
import Block from "../components/admin/Block.vue";
import BlockDialog from "../components/admin/BlockDialog.vue";

@Component({ components: { Sidebar, Participants, Block, BlockDialog } })
export default class SeminarDetails extends Vue {
  @Getter("seminars/loading") loading!: boolean;
  @Getter("seminars/adding") adding!: boolean;
  @Getter("seminars/selected") seminar?: Seminar;

  @Action("seminars/get") getSeminar!: (id: number) => Promise<void>;
  @Action("seminars/edit") editSeminar!: (
    s: EditSeminarRequest,
  ) => Promise<void>;
  @Action("seminars/remove") removeSeminar!: (id: number) => Promise<void>;

  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  // seminar
  seminarDialog = false;
  editing = false;
  newSeminar: Seminar | null = null;
  logo: File | null = null;
  preview: string | null = null;
  openEditSeminar() {
    if (!this.seminar) return;
    this.editing = true;
    this.seminarDialog = true;
    this.newSeminar = Object.assign({}, this.seminar);
  }
  closeSeminarDialog() {
    this.editing = false;
    this.newSeminar = null;
    this.seminarDialog = false;
  }
  async confirmSeminarChanges() {
    if (!this.seminar || !this.editing || !this.newSeminar) return;

    if (!this.newSeminar.name || !this.newSeminar.description) {
      this.displaySnackbar("Must provide title and body for the seminar");
      return;
    }

    this.seminarDialog = false;
    try {
      await this.editSeminar({
        seminarId: this.seminar.id,
        name: this.newSeminar.name,
        description: this.newSeminar.description,
        logo: this.logo,
      });
    } catch (error) {
      console.log(error);
    }
    this.newSeminar = null;
  }

  // todo no loading when creating a seminar

  handleFileChanged(f: File | null) {
    if (!f) this.preview = null;
    else {
      const reader = new FileReader();
      reader.onload = _ => {
        this.preview = reader.result as string;
      };
      reader.readAsDataURL(f);
    }
  }

  deleteDialog = false;
  async deleteSeminar() {
    if (!this.newSeminar) return;
    this.seminarDialog = false;
    await this.removeSeminar(this.newSeminar.id);
    this.deleteDialog = false;
    this.$router.replace("/seminars/admin");
  }

  // blocks
  blockDialog = false;
  newBlock: SeminarBlock | null = null;
  openAddBlock() {
    if (!this.seminar) return;
    this.blockDialog = true;
    this.newBlock = {
      id: 0,
      seminarId: this.seminar.id,
      title: "",
      description: "",
      elements: [],
    };
  }

  created() {
    const { id } = this.$route.params;
    this.getSeminar(Number(id));
  }
}
